@import './base/bootstrap-extended/include'; // Bootstrap includes
@import './base/components/include'; // Components includes

// --- file input button for dark layout ----- //
.dark-layout {
    .form-control {
        &[type='file'] {
            &::file-selector-button {
                background-color: $theme-dark-input-bg;
            }
        }
    }
}

.dataTable{
    tfoot {
        display: table-header-group;
    }
}

table.table-frozen{

    .headcol {
        position: -webkit-sticky;
        position: sticky;
        background-color: white;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        width: 230px;
        min-width: 230px;
        max-width: 230px;
        left: 0px;
    }

}



